import axios from "axios";
import { useEffect, useState } from "react";

const voiceSettings = {
  stability: 1,
  similarity_boost: 1,
};

export const useVoice = () => {
  const [key, setElevenKey] = useState("");
  const [gender, setGender] = useState("");

  useEffect(() => {
    axios(
      `${
        process.env.VOICE_API_BASE_URL ||
        "https://ai-robot-api.shutterstudio.io"
      }/eleven/config`
    ).then(async ({ data }) => {
      setElevenKey(data.elevenKey);
      setGender(data.readerGender);
    });
  }, []);

  if (key)
    return async (text) => {
      let voiceId;
      if (gender === "male") {
        voiceId = "5Q0t7uMcjvnagumLfvZi";
      } else if (gender === "female") {
        voiceId = "EXAVITQu4vr4xnSDxMaL";
      }
      const baseUrl = "https://api.elevenlabs.io/v1/text-to-speech";
      const headers = {
        "Content-Type": "application/json",
        "xi-api-key": key,
      };

      const requestBody = {
        model_id: "eleven_multilingual_v2",
        text,
        voice_settings: voiceSettings,
      };

      try {
        const response = await axios.post(
          `${baseUrl}/${voiceId}`,
          requestBody,
          {
            headers,
            responseType: "blob",
          }
        );

        if (response.status === 200) {
          const audio = new Audio(URL.createObjectURL(response.data));
          await audio.play();
        } else {
          console.log("Error: Unable to stream audio.");
        }
      } catch (error) {
        console.log("Error: Unable to stream audio.");
      }
    };
};
